const PRODUCTION_SUBDOMAIN = 'www'
const PREPROD_ACCOUNTS_SUBDOMAIN = 'accounts.preprod'
const PRODUCTION_ACCOUNTS_SUBDOMAIN = 'accounts'

export function useAuthOrigin() {
  const { hostname, origin } = window.location
  const [subdomain] = hostname.split('.')

  if (subdomain === PRODUCTION_SUBDOMAIN) {
    return origin.replace(subdomain, PRODUCTION_ACCOUNTS_SUBDOMAIN)
  }

  return origin.replace(subdomain, PREPROD_ACCOUNTS_SUBDOMAIN)
}
